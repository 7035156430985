import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ubuntu/flomesh.io-main/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "使用"
    }}>{`使用`}</h2>
    <p>{`Pipy Repo 的启动很简单，默认监控 `}<inlineCode parentName="p">{`6060`}</inlineCode>{` 端口，也可以通过 `}<inlineCode parentName="p">{`--admin-port`}</inlineCode>{` 参数来使用其他端口：`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ pipy
2021-10-09 10:48:37 [info] [codebase] Starting codebase service...
2021-10-09 10:48:37 [info] [listener] Listening on port 6060 at ::
`}</code></pre>
    <p>{`在浏览器中打开 `}<inlineCode parentName="p">{`http://localhost:6060`}</inlineCode>{`。`}</p>
    <p><img parentName="p" {...{
        "src": "/img/docs/repo/repo-home.png",
        "alt": null
      }}></img></p>
    <p>{`内置的文档：`}</p>
    <p><img parentName="p" {...{
        "src": "/img/docs/repo/pipy-doc.png",
        "alt": null
      }}></img></p>
    <p>{`也可以通过 `}<inlineCode parentName="p">{`New Codebase`}</inlineCode>{` 来添加新的代码库。新建的代码库，会默认添加一个 `}<inlineCode parentName="p">{`main.js`}</inlineCode>{` 脚本作为入口脚本：`}</p>
    <p><img parentName="p" {...{
        "src": "/img/docs/repo/new-codebase.png",
        "alt": null
      }}></img></p>
    <p><inlineCode parentName="p">{`main.js`}</inlineCode>{` 的初始内容：`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`pipy()
`}</code></pre>
    <p>{`我们为其增加些内容：`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`pipy()
  .listen(8080)
  .serveHTTP(
    new Message('Hi!')
  )
`}</code></pre>
    <h3 {...{
      "id": "代码库编辑器"
    }}>{`代码库编辑器`}</h3>
    <p>{`看过`}<a parentName="p" {...{
        "href": "/operating/admin-gui/"
      }}>{`开发控制台`}</a>{`的话，对这个界面就不会太陌生。如果未看过，建议先看下，这里只介绍差异的部分。`}</p>
    <p><img parentName="p" {...{
        "src": "/img/docs/repo/codebase-editor.png",
        "alt": null
      }}></img></p>
    <ol>
      <li parentName="ol">{`文件列表：与开发控制台稍有不同。假如文件后面有个 `}<inlineCode parentName="li">{`*`}</inlineCode>{`，`}<strong parentName="li">{`表示文件修改，但并未提交。`}</strong></li>
      <li parentName="ol">{`将修改但为提交的文件重置为已提交的版本。`}</li>
      <li parentName="ol">{`提交改动（会更新 codebase 的版本号）。`}</li>
      <li parentName="ol">{`将当前 codebase 设置成 base。`}</li>
      <li parentName="ol">{`查看/创建当前 codebase 的衍生。`}</li>
    </ol>
    <h3 {...{
      "id": "调试脚本"
    }}>{`调试脚本`}</h3>
    <p>{`参考`}<a parentName="p" {...{
        "href": "/operating/admin-gui/"
      }}>{`开发控制台`}</a>{`的使用，点击右上角的 ▶︎ 按钮即可运行脚本。`}</p>
    <p><img parentName="p" {...{
        "src": "/img/docs/repo/codebase-running.png",
        "alt": null
      }}></img></p>
    <p>{`尝试访问下 `}<inlineCode parentName="p">{`localhost:8080`}</inlineCode>{`：`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl localhost:8080
Hi!
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      